import React, { useLayoutEffect } from 'react'
import '../src/components/style.css'
import '../src/components/assets/css/bootstrap.min.css'
import '../src/components/assets/css/meanmenu.min.css'
import '../src/components/assets/css/responsive.css'
import '../src/components/venobox/venobox.css'
import '../src/components/assets/css/owl.transitions.css'
import '../src/components/assets/css/owl.carousel.min.css'
import '../src/components/assets/css/all.min.css'
import '../src/components/assets/css/animated-text.css'
import '../src/components/assets/css/animate.css'
import '../src/components/assets/css/flaticon.css'
import '../src/components/assets/css/theme-default.css'
import '../src/components/assets/css/meanmenu.min.css'
import { BrowserRouter,Route,Routes } from 'react-router-dom'
import Footer from './pages/Footer'
import Homepage from './pages/Homepage';
import Scrollup from './pages/Scrollup'
import Aboutus from './pages/Aboutus'
import Contact from './pages/Contact'
import Team from './pages/Team'
import Service from './pages/Service'
import Oil from './pages/Oil'
import Oli_gas_whole_sale_rate from './pages/oli-gas-whole-sale-rate'
import Gas from './pages/Gas'
import Buy from './pages/Buy'
import Invest from './pages/Invest'
import Navbar from './pages/Navbar'
import Portfolio from './pages/Portfolio'
function App() {

  


  return (
    <div className="App">

<BrowserRouter >
<Navbar/>

<Routes>
<Route path='/' element={<Homepage/>} />
<Route path='/Aboutus' element={<Aboutus/>} />
<Route path='/Contact' element={<Contact/>} />
<Route path='/Team' element={<Team/>} />
<Route path='/Service' element={<Service/>} />
<Route path='/Oil' element={<Oil/>} />
<Route path='/Gas' element={<Gas/>} />
<Route path='/Oli_gas_whole_sale_rate' element={<Oli_gas_whole_sale_rate/>} />
<Route path='/Buy' element={<Buy/>} />
<Route path='/Invest' element={<Invest/>} />
<Route path='/Portfolio' element={<Portfolio/>} />



<Route path='*' element={<Homepage/>}/>
</Routes>

<Scrollup/>
<Footer/>
</BrowserRouter>



    </div>
  );
}

export default App;
