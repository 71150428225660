import React from 'react'
import Img1 from '../components/assets/images/slider/call.png'
import Img2 from '../components/assets/images/slider/hero-img.png'
import Img3 from '../components/assets/images/slider/hero-shape.png'
import Img4 from '../components/assets/images/slider/hero-shape2.png'
import Img5 from '../components/assets/images/resource/service-icon.png'
import Img6 from '../components/assets/images/resource/service-icon2.png'
import Img7 from '../components/assets/images/resource/service-icon3.png'
import Img8 from '../components/assets/images/resource/service-icon.png'
import Img9 from '../components/assets/images/about/about.png'
import Img10 from '../components/assets/images/about/about-shape2.png'
import Img11 from '../components/assets/images/resource/all-shape.png'
import Img12 from '../components/assets/images/about/about2.png'
import Img13 from '../components/assets/images/about/ab-shape.png'
import Img14 from '../components/assets/images/resource/red-dot.png'
import Img15 from '../components/assets/images/resource/all-shape.png'
import Img16 from '../components/assets/images/project/case2.png'
import Img17 from '../components/assets/images/project/case3.png'
import Img18 from '../components/assets/images/project/case1.png'
import Img19 from '../components/assets/images/resource/process-shape.png'
import Img20 from '../components/assets/images/resource/dreamit-shape.png'
import Img21 from '../components/img/cblsoltionslogo.png'
import Img22 from '../components/img/istockphoto-1181906881-612x612.png'
import Img23 from '../components/img/SDISOLUTION.png'
import Img24 from '../components/img/thunder-shop-logo-design-electric-shop-or-fast-shop-logo-shopping-bag-combined-with-energy-or-lightning-bolt-icon-vector.png'
import Img25 from '../components/assets/images/resource/skill.png'
import Img26 from '../components/assets/images/resource/skill-shape.png'
import Img27 from '../components/assets/images/resource/all-shape.png'
import Img28 from '../components/assets/images/resource/blog1.png'
import Img29 from '../components/assets/images/resource/blog-icon.png'
import Img30 from '../components/assets/images/resource/blog2.png'
import Img31 from '../components/assets/images/resource/blog-icon.png'
import Img32 from '../components/assets/images/resource/blog3.png'
import Img33 from '../components/assets/images/resource/blog-icon.png'
import {BiPlus} from 'react-icons/bi'
import {Link} from 'react-router-dom'
import Img34 from'../components/img/WhatsApp-Image-2023-03-21-at-8.57.46-PM-1920x1280-1.jpeg'

const Homepage = () => {
  return (
    <div>
<div class="slider-area d-flex align-items-center" >
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-7 col-md-6">
				<div class="slider-content">
					<h3> Work With Nazreen Consulting Consultant </h3>
					<h1> IT Solution For </h1>
					<h1> Business <span>Success</span> </h1>
					<p> Nazreen Consulting will providing a best solutions to develop your business
						<br/>Modifiy your business with new different Technologies
					</p>
				</div>
				<div class="lines pt-20 pb-40">
					<div class="line"></div>
				</div>
				<div className='d-flex  justify-content-around justify-content-lg-start'>
				<div class="slider-button">
					<Link to="/Contact"> Contact Us <BiPlus/> </Link>
				</div>
				<div class="slider-button">
					<Link to="/Portfolio"> Portfolio <BiPlus/> </Link>
				</div>
				</div>
				
			</div>
			<div class="col-lg-5 col-md-6 ">
				<div class="slider-thumb">
					<img src={Img2} alt=""/>
				</div>
				<div class="video-icon">
					<Link class="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" to="https://youtu.be/BS4TUd7FJSg"><i class="fa fa-play"></i></Link>
				</div>
			</div>

			<div class="slider-shape">
				<div class="slider-shape-thumb">
					<img src={Img3} alt=""/>
				</div>
				<div class="slider-shape-thumb2">
					<img src={Img4} alt=""/>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="service-area">
	<div class="container">
		<div class="row align-items-center mb-90">
			<div class="col-lg-7 col-md-6 pl-0">
				<div class="itsoft-section-title">
					<h2> We Run All Kinds Of Services</h2>
					<h2> From <span> Technologies </span></h2>
				</div>
			</div>
			<div class="col-lg-5 col-md-6">
				<div class="itsoft-button text-center text-lg-right">
					<Link to="/Service"> All Service <BiPlus/> </Link>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-3 col-md-6 p-0">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img5} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> App Development</h2>
						</div>
						<div class="service-number">
							<h1> 01 </h1>
						</div>
						<div class="em-service-text">
							<p>Mobile application development is the process of creating software applications.</p>
						</div>
						<div class="service-button">
							<Link to="/Contact"> Contact Us <BiPlus/> </Link>
						</div>
						
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 p-0">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img6} alt=""/>
						</div>
						<div class="em-service-title">
							<h2>Web Development
 </h2>
						</div>
						<div class="service-number">
							<h1> 02 </h1>
						</div>
						<div class="em-service-text">
							<p>Web development refers in general to the tasks associated with developing websites.</p>
						</div>
						<div class="service-button">
						<Link to="/Contact"> Contact Us<BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 p-0">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img7} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Software Development
 </h2>
						</div>
						<div class="service-number">
							<h1> 03 </h1>
						</div>
						<div class="em-service-text">
							<p>Software development is the process of designing, creating, testing, and maintaining</p>
						</div>
						<div class="service-button">
						<Link to="/Contact"> Contact Us <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 p-0">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img8} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Graphic Designing</h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p>Graphic design is an interdisciplinary branch of design and of the fine arts.</p>
						</div>
						<div class="service-button">
						<Link to="/Contact"> Contact Us <BiPlus/></Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 p-0">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img8} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Customer Support</h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p>Customer service is the direct one-on-one interaction between a consumer.</p>
						</div>
						<div class="service-button">
						<Link to="/Contact"> Contact Us <BiPlus/></Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 p-0">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img7} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Call Center </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p>You might envision a call center as a warehouse jammed with tiny desks</p>
						</div>
						<div class="service-button">
						<Link to="/Contact"> Contact Us <BiPlus/></Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 p-0">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img6} alt=""/>
						</div>
						<div class="em-service-title">
							<h2>Game Development</h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p>Video game developers help transform games from a concept to a playable reality.</p>
						</div>
						<div class="service-button">
						<Link to="/Contact"> Contact Us <BiPlus/></Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6 p-0">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img5} alt=""/>
						</div>
						<div class="em-service-title">
							<h2>eCommerce VA</h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p> E-commerce virtual assistants are bots that use machine learning and natural language </p>
						</div>
						<div class="service-button">
						<Link to="/Contact"> Contact Us <BiPlus/></Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>



<div class="about-area">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-6">
				<div class="dreamit-about-thumb">
					<img src={Img9} alt=""/>
					<div class="about-shape-thumb1 bounce-animate2">
						<img src={Img10} alt=""/>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-6 ">
				<div class="itsoft-section-title white pb-10">
					<h2> The Fastest Way To Achieve</h2>
					<h2> Your Business <span> Success </span></h2>
					<p>Continually generate 2.0 communities and client-focused products. In synergize strategic manufactured products whereas levera.</p>
				</div>
				<div class="lines style-two pb-15">
					<div class="line"></div>
				</div>
				<div class="dreamit-icon-box">
					<div class="dreamit-icon-title">
						<h4> We are Since <span>2019 to Present</span> to give good business all over the world </h4>
					</div>
					<div class="dreamit-icon-list">
						<ul>
							<li><i class="flaticon-tick"></i> <span> We help out Client to  grow their Business to upper level  </span></li>
							<li><i class="flaticon-tick"></i> <span> We give next level tips to advance your business </span></li>
							<li><i class="flaticon-tick"></i> <span> We follow trends and give better opportunities to clients to eloborate their business </span></li>
						</ul>
					</div>
				</div>
				<div class="about-button">
					<Link to="/Aboutus"> Learn More <BiPlus/> </Link>
				</div>
			</div>
			<div class="about-shape-thumb2 bounce-animate4">
				<img src={Img11} alt=""/>
			</div>
		</div>
		<div class="row counter-vip">
			<div class="col-lg-12">
				<div class="couter-top-title">
					<h3> Nazreen Consulting Finished this Achivement in 10 Years </h3>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="counter-single-box">
					<div class="counter-title">
						<h4 class="counter">1,372</h4>
						<p>Project Complete</p>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="counter-single-box">
					<div class="counter-title">
						<h4 class="counter">23</h4>
						<span>+</span>
						<p>Years Experience</p>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="counter-single-box">
					<div class="counter-title">
						<h4 class="counter">38</h4>
						<span>+</span>
						<p>Exper Member</p>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="counter-single-box">
					<div class="counter-title">
						<h4 class="counter">1,372</h4>
						<span>+</span>
						<p>Satisfactions Rate</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>




<div class="about-area style-two">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-md-6 ">
				<div class="itsoft-section-title">
					<h2> What Say Our CEO, About</h2>
					<h2> Nazreen Consulting <span> Customers </span></h2>
					<div class="lines style-three pt-20 pb-10">
						<div class="line"></div>
					</div>
					<p class="about-text"> “We help Our Clients to Renew Their Business Function to Create Brandable Organizations” </p>
					<p class="about-text2">Nazreen Consulting is acquire relevant knowledge and skill for your business and allows network and build good relationships also get practical experience, Develop a niche and build a good portfolio, stay updated with industry trends. Nazreen Consulting always develop a strong communication with their clients and give them a skillful presentation to adobe the market trends, give a seek mentorship and guidance to pursue advanced education. Nazreen Consulting is have a good experience to manage all things that help out to grow your business. </p>
				</div>
				<div class="dreamit-ceo-box ">
					<div class="dreamit-ceo-title ">
						<h4> Hasnain Brohi
</h4>
						<span>CEO / Founder Of Nazreen Consulting
</span>
					</div>
				</div>
				<div class="about-button text-center text-lg-left">
					<Link to="/Aboutus"> Clients Review <BiPlus/> </Link>
				</div>
			</div>
			<div class="col-lg-6 col-md-6">
				<div class="dreamit-about-thumb1">
					<img width='100%' src={Img34} alt=""/>
				</div>
				<div class="about-shape-box">
					<div class="about-shape-thumb bounce-animate">
						<img src={Img13} alt=""/>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="case-study-area">
	<div class="container">
		<div class="row case-study-bg align-items-center mb-40">
			<div class="col-lg-6 col-md-6">
				<div class="itsoft-section-title white ">
					<h2> We Serve the Best Works</h2>
					<h2> View <span> Case Studies </span></h2>
				</div>
			</div>
			<div class="col-lg-6 col-md-6">
				<div class="itsoft-button text-center text-lg-right">
					<Link to="#"> All Project <BiPlus/> </Link>
				</div>
			</div>
			<div class="case-study-shape">
				<div class="case-shape-thumb bounce-animate4">
					<img src={Img14} alt=""/>
				</div>
				<div class="case-shape-thumb1 bounce-animate2">
					<img src={Img15} alt=""/>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-lg-4">
					<div class="case-study-single-box">
						<div class="case-study-thumb">
							<img src={Img16} alt=""/>
							<div class="case-study-content">
								<div class="case-study-title">
									<h6> Consulting </h6>
								    <h3> <Link to="#"> Consulting for Business Organizations</Link> </h3>
								</div>
								<div class="case-button">
									<Link to="#">Read More <BiPlus/> </Link>
								</div>
							</div>
							<div class="video-icon style-two">
								<Link class="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" to="#"><i class="fa fa-play"></i></Link>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="case-study-single-box">
						<div class="case-study-thumb">
							<img src={Img17} alt=""/>
							<div class="case-study-content">
								<div class="case-study-title">
									<h6> Consulting </h6>
								    <h3> <Link to="#"> Strategy and Planning Building Idea</Link> </h3>
								</div>
								<div class="case-button">
									<Link to="#">Read More <BiPlus/></Link>
								</div>
							</div>
							<div class="video-icon style-two">
								<Link class="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" to="#"><i class="fa fa-play"></i></Link>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4">
					<div class="case-study-single-box">
						<div class="case-study-thumb">
							<img src={Img18} alt=""/>
							<div class="case-study-content">
								<div class="case-study-title">
									<h6> Consulting </h6>
								    <h3> <Link to="#"> Corporate Finance for Trend Markets</Link> </h3>
								</div>
								<div class="case-button">
									<Link to="#">Read More <BiPlus/> </Link>
								</div>
							</div>
							<div class="video-icon style-two">
								<Link class="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" to="#"><i class="fa fa-play"></i></Link>
							</div>
						</div>
					</div>
				</div>
			
			</div>
		</div>
	</div>
</div>


<div class="process-area">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-6">
				<div class="row process-bg">
					<div class="col-lg-6 col-md-6 pl-10 pr-10">
						<div class="process-single-box">
							<div class="process-number">
								<span>1</span>
							</div>
							<div class="process-title">
								<h3>Idea <span> Generate </span></h3>
								<p>Generate Exclusive Idea For Business</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-6 pl-10 pr-10">
						<div class="process-single-box upper">
							<div class="process-number">
								<span>2</span>
							</div>
							<div class="process-title">
								<h3> System <span> Design </span></h3>
								<p>Design Your System Like User Frindly</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-6 pl-10 pr-10">
						<div class="process-single-box upper1">
							<div class="process-number">
								<span>3</span>
							</div>
							<div class="process-title">
								<h3> 24/7 <span> Support </span></h3>
								<p>We Are Available Any Time For Your Quries</p>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-6 pl-10 pr-10">
						<div class="process-single-box upper2">
							<div class="process-number">
								<span>4</span>
							</div>
							<div class="process-title">
								<h3> Sales <span> Generate </span></h3>
								<p>Give 100% In Your Work Will Generate Your Sales</p>
							</div>
						</div>
					</div>
					<div class="process-shape">
						<div class="process-thumb">
							<img src={Img19} alt=""/>
						</div>
						<div class="process-thumb1">
							<img src={Img20} alt=""/>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-6 pl-50">
				<div class="itsoft-section-title">
					<h2> The Fatest Way To Achieve </h2>
					<h2> Technology <span> Consulting </span></h2>
				</div>
				<div class="lines style-three pt-30 pb-10">
					<div class="line"></div>
				</div>
				<div class="dreamit-smart-title">
					<h4> For Choose <span>Nazreen Consulting</span> Before know who we are? </h4>
					<p>Nazreen Consulting worldwide value before in seamless process data Collaboratively initiate strategic expertise after functionalized models in. Synergistically synthesize front-end convergence whereas resource tosynergy. Collaboratively the expedite focused architectures rather than resources. Enthusiastically extensive.</p>
				</div>
				<div class="about-button">
					<Link to="/Aboutus"> More About <BiPlus/> </Link>
				</div>
			</div>
		</div>
		<div class="row pt-50">
			<div class="col-lg-12">
				<div class="dreamits-top-title">
					<h3> More Then 5K+ Brands with work Nazreen Consulting</h3>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="brand-list row">
				<div class="col-6 col-sm-3">
					<div class="brand-single-box">
						<div class="brand-thumb">
							<img src={Img21} alt="" width={200}/>
						</div>
					</div>
				</div>
				<div class="col-6 col-sm-3">
					<div class="brand-single-box">
						<div class="brand-thumb">
							<img src={Img22} alt="" width={200}/>
						</div>
					</div>
				</div>
				<div class="col-6  col-sm-3">
					<div class="brand-single-box">
						<div class="brand-thumb">
							<img src={Img23} alt="" width={200}/>
						</div>
					</div>
				</div>
				<div class="col-6 col-sm-3 ">
					<div class="brand-single-box">
						<div class="brand-thumb">
							<img src={Img24} alt="" width={200}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="skill-area">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-lg-6 col-md-6 pr-0">
				<div class="extra-animation-div">
					<div class="itsoft-section-title white">
						<h2> Join the Community to learn </h2>
						<h2> About our <span>Company</span></h2>
					</div>
					<div class="lines style-three upper pt-30 pb-10">
						<div class="line"></div>
					</div>
					<div class="dreamit-smart-title">
						<p class="slill-text">Continually generate 2.0 communities and client-focused products. In synergize strategic manufactured products whereas levera.</p>
					</div>
				</div>
				<div class="prossess-ber-plugin pt-20">
                    <span class="prosses-bar">Business Strategy</span>
			
                    <div id="bar1" class="barfiller">
					<div class="progress">
  <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: '97%'}}>97%</div>
  
</div>
                    </div>  
                    <span class="prosses-bar">Technology Consulting</span>
                    <div id="bar2" class="barfiller">
					<div class="progress">
  <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: '90%'}}>90%</div>
  
</div>
                    </div>
                    <span class="prosses-bar">Product Development</span>
                    <div id="bar3" class="barfiller">
					<div class="progress">
  <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: '95%'}}>95%</div>
  
</div>
                    </div> 
                </div>
			</div>
			<div class="col-lg-6 col-md-6">
				<div class="slill-single-thumb pl-50 ml-1">
					<img src={Img25} alt=""/>
					<div class="skill-thumb-content">
						<div class="skill-title">
							<h3 class="counter"> 17 </h3>
							<span>+</span>
							<h5> YEARS EXPERIENCE </h5>
						</div>
					</div>
					<div class="skill-shape dance">
						<img src={Img26} alt=""/>
					</div>
					<div class="skill-shape1 bounce-animate2">
						<img src={Img27} alt=""/>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>



<div class="blog-area blog-new">
	<div class="container">
		<div class="row align-items-center mb-40">
			<div class="col-lg-7 col-md-6">
				<div class="itsoft-section-title">
					<h2 className='text-white'> We’re here to share story </h2>
					<h2 className='text-dark'> from Latest <span> News </span></h2>
				</div>
			</div>
			<div class="col-lg-5 col-md-6">
				<div class="itsoft-button text-center text-lg-right mt-3">
					<Link to="#"> View all Blog <BiPlus/> </Link>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-md-6">
				<div class="single-blog-box">
					<div class="single-blog-thumb">
						<img src={Img28} alt=""/>
						<div class="blog-top-button">
							<Link to="#"> GRAPHIC </Link>
						</div>
					</div>
					<div class="em-blog-content">
						<div class="meta-blog-text">
							<p> August 25, 2022 </p>
						</div>
						<div class="em-blog-title">
							<h2> <Link to="#"> Popular Consultants are big Meetup 2022 </Link> </h2>
						</div>
						<div class="em-blog-icon">
							<div class="em-blog-thumb">
								<img src={Img29} alt=""/>
							</div>
							<div class="em-blog-icon-title">
								<h6> Alex Collins </h6>
							</div>
						</div>
						<div class="blog-button">
							<Link to="#"> Learn More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-6">
				<div class="single-blog-box">
					<div class="single-blog-thumb">
						<img src={Img30} alt=""/>
						<div class="blog-top-button">
							<Link to="#"> DEVELOPMENT </Link>
						</div>
					</div>
					<div class="em-blog-content">
						<div class="meta-blog-text">
							<p> August 21, 2022 </p>
						</div>
						<div class="em-blog-title">
							<h2> <Link to="#"> How to Increase Business Products Sales </Link> </h2>
						</div>
						<div class="em-blog-icon">
							<div class="em-blog-thumb">
								<img src={Img31} alt=""/>
							</div>
							<div class="em-blog-icon-title">
								<h6> Julia Moris </h6>
							</div>
						</div>
						<div class="blog-button">
							<Link to="#"> Learn More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-6">
				<div class="single-blog-box">
					<div class="single-blog-thumb">
						<img src={Img32} alt=""/>
						<div class="blog-top-button">
							<Link to="#"> DESIGN </Link>
						</div>
					</div>
					<div class="em-blog-content">
						<div class="meta-blog-text">
							<p> August 20, 2022 </p>
						</div>
						<div class="em-blog-title">
							<h2> <Link to="#"> Top 10 Most Populars Google Chrome Extension </Link> </h2>
						</div>
						<div class="em-blog-icon">
							<div class="em-blog-thumb">
								<img src={Img33} alt=""/>
							</div>
							<div class="em-blog-icon-title">
								<h6> Amantha </h6>
							</div>
						</div>
						<div class="blog-button">
							<Link to="#"> Learn More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>




    </div>
  )
}

export default Homepage