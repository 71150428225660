import React from 'react'
import Img1 from '../components/assets/images/resource/service-icon.png'
import Img2 from '../components/assets/images/resource/service-icon2.png'
import Img3 from '../components/assets/images/resource/service-icon3.png'
import Img4 from '../components/assets/images/resource/service-icon.png'
import Img5 from '../components/assets/images/about/about-2.png'
import Img6 from '../components/assets/images/about/about-shpe.png'
import Img7 from '../components/assets/images/about/about-shape4.png'
import Img8 from '../components/assets/images/resource/main1.png'
import Img9 from '../components/assets/images/resource/main3.png'
import Img10 from '../components/assets/images/resource/main2.png'
import Img11 from '../components/img/cblsoltionslogo.png'
import Img12 from '../components/img/SDISOLUTION.png'
import Img13 from '../components/img/istockphoto-1181906881-612x612.png'
import Img14 from '../components/img/thunder-shop-logo-design-electric-shop-or-fast-shop-logo-shopping-bag-combined-with-energy-or-lightning-bolt-icon-vector.png'
import {BiPlus} from 'react-icons/bi'
import {BsArrowRightCircle} from 'react-icons/bs'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {VscSettingsGear} from 'react-icons/vsc'
import {BiPauseCircle} from 'react-icons/bi'
import {Link} from 'react-router-dom'

const Service = () => {
    const percentage = 95;


  return (
    <div>
        <div class="breadcumb-area d-flex align-items-center">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1> Our Service </h1>
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Service </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><Link to="index-2.html">Home</Link></li>
						<li> Our Service </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>




<div class="service-area">
	<div class="container">
		<div class="row">
			<div class="col-lg-7 col-md-6">
				<div class="itsoft-section-title pb-90">
					<h2> We Run All Kinds Of Services From <span> Technologies </span></h2>
				</div>
			</div>
			<div class="col-lg-5 col-md-6">
				<div class="itsoft-button text-right pt-20">
					<Link to="/Service"> All Service <BiPlus/> </Link>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img1} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> App Development</h2>
						</div>
						<div class="service-number">
							<h1> 01 </h1>
						</div>
						<div class="em-service-text">
							<p> Mobile application development is the process of creating software applications.</p>
						</div>
						<div class="service-button">
							<Link to="/Service"> Read More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img2} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Web Development
 </h2>
						</div>
						<div class="service-number">
							<h1> 02 </h1>
						</div>
						<div class="em-service-text">
							<p>Web development refers in general to the tasks associated with developing websites.</p>
						</div>
						<div class="service-button">
							<Link to="/Service"> Read More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img3} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Software Development
 </h2>
						</div>
						<div class="service-number">
							<h1> 03 </h1>
						</div>
						<div class="em-service-text">
							<p>Software development is the process of designing, creating, testing, and maintaining</p>
						</div>
						<div class="service-button">
							<Link to="/Service"> Read More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img4} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Graphic Designing
 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p>Graphic design is an interdisciplinary branch of design and of the fine arts.</p>
						</div>
						<div class="service-button">
							<Link to="/Service"> Read More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div><div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img4} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Customer Support

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p>Customer service is the direct one-on-one interaction between a consumer.</p>
						</div>
						<div class="service-button">
							<Link to="/Service"> Read More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img3} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Call Center

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p>You might envision a call center as a warehouse jammed with tiny desks</p>
						</div>
						<div class="service-button">
							<Link to="/Service"> Read More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img2} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> Game Development

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p>Video game developers help transform games from a concept to a playable reality.</p>
						</div>
						<div class="service-button">
							<Link to="/Service"> Read More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon">
							<img src={Img1} alt=""/>
						</div>
						<div class="em-service-title">
							<h2> eCommerce VA

 </h2>
						</div>
						<div class="service-number">
							<h1> 04 </h1>
						</div>
						<div class="em-service-text">
							<p> E-commerce virtual assistants are bots that use machine learning and natural language </p>
						</div>
						<div class="service-button">
							<Link to="/Service"> Read More <BiPlus/> </Link>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</div>


<div class="about-area style-three upper">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 col-md-6">
				<div class="dreamit-about-thumb">
					<img src={Img5} alt=""/>
					<div class="about-shape-thumb1 bounce-animate2">
						<img src={Img6} alt=""/>
					</div>
					<div class="about-shape-thumb3 bounce-animate4">
						<img src={Img7} alt=""/>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-md-6 ">
				<div class="itsoft-section-title">
					<h5> About Nazreen Consulting </h5>
					<h2> We Are Global Stakeholder Over 2000+  <span> Companies </span></h2>
					<p class="about-text1"> Welcome to Nazreen Consulting, a leading global software house serving over 2000+ customers worldwide. As trusted global stakeholders, we are dedicated to delivering innovative solutions that drive success for our diverse clientele. With our expertise and commitment to excellence, we are proud to be a preferred partner for businesses across industries.</p>
				</div>
				<div class="dreamit-icon-box">
					<div class="dreamit-icon-list">
						<ul>
							<li><span style={{color:'red',fontSize:'22px'}}><BsArrowRightCircle/></span> <span> Technologies Expertise And Track Record Of Success </span></li>
							<li><span style={{color:'red',fontSize:'22px'}}><BsArrowRightCircle/></span> <span> Clients Testimonials And Client-Centric Approach</span></li>
						</ul>
					</div>
				</div>

				<div class="progress-box">
                <div class="circle_percent" data-percent="95" style={{background:'white'}}>
                    <CircularProgressbar
  value={percentage}
  text={`${percentage}%`}
  styles={buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 0.25,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'

    textSize:`28px`,
   

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',

    // Colors
    pathColor: `red`,
    textColor: 'black',
    trailColor: '#d6d6d6',
    backgroundColor: '#3e98c7',
  })}
/>;
				    </div>
				    <div class="circle-progress-title">
			        	<h4> Clients <br/> Satisfactions </h4>
			        </div>
				    <div class="extra-progress">
                    <div class="circle_percent" data-percent="95" style={{background:'white'}}>
                    <CircularProgressbar
  value={percentage}
  text={`${percentage}%`}
  styles={buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 0.25,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'

    textSize:`28px`,
   

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',

    // Colors
    pathColor: `red`,
    textColor: 'black',
    trailColor: '#d6d6d6',
    backgroundColor: '#3e98c7',
  })}
/>;
				    </div>
				    <div class="circle-progress-title">
			        	<h4> Clients <br/> Review </h4>
			        </div>
				    </div>
				</div>
				<div class="about-button">
                <Link to="/Aboutus"><VscSettingsGear/>  More About </Link>
				</div>
			</div>
		</div>
	</div>
</div>




<div class="call-do-action-section">
	<div class="container">
		<div class="call-do-action-video text-center mb-35">
			<div class="video-icon-cda">
				<Link class="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true" to="#"><BiPauseCircle/></Link>
			</div>
		</div>
		<div class="call-do-action-content text-center">
			<h2 class="text-white">We Deliver Solution With The Goal Of <br/><span class="sub-title"> A Trusting Relationships</span></h2>
			<p class="text-white">Appropriate for your specific business, making it easy for you to have quality IT services.</p>
			<div class="btn-common btn-cda mt-40">
				<Link to="#">Watch More</Link>
			</div>
		</div>
	</div>
</div>




<div class="service-area style-two upper">
	<div class="container">
		<div class="row serivce-bg">
			<div class="col-lg-4 col-md-6">
				<div class="dreamit-service-box businessimg">
					<div class="service-box-inner">
						<div class="em-service-icon1">
							<img src={Img8} alt=""/>
						</div>
						<div class="em-service-title">
							<h3> Business Planning </h3>
						</div>
						<div class="em-service-text">
							<p> A business plan is a document describing a company's core business activities and how it plans to achieve its goals.Startup companies use business plans to get off the ground and attract outside investors. </p>
						</div>
						<div class="service-bar"></div>
					</div>
					<div class="dreamit-service-back">
						<div class="serivce-back-title">
							<h3> Business </h3>
							<h2> Business Planning </h2>
						</div>
						<div class="serivce-back-icon">
							<Link to="#">+</Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-6">
				<div class="dreamit-service-box">
					<div class="service-box-inner">
						<div class="em-service-icon1">
							<img src={Img9} alt=""/>
						</div>
						<div class="em-service-title">
							<h3> Cloud Solutions </h3>
						</div>
						<div class="em-service-text">
							<p> Cloud solutions, also known as cloud computing or cloud services, deliver IT resources on demand over the Internet.With cloud solutions, IT resources can scale up or down quickly to meet business demands. </p>
						</div>
						<div class="service-bar"></div>
					</div>
					<div class="dreamit-service-back">
						<div class="serivce-back-title">
							<h3> Cloud </h3>
							<h2> Cloud Solutions </h2>
						</div>
						<div class="serivce-back-icon">
							<Link to="#">+</Link>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-6">
				<div class="dreamit-service-box customerimg">
					<div class="service-box-inner">
						<div class="em-service-icon1">
							<img src={Img10} alt=""/>
						</div>
						<div class="em-service-title">
							<h3> Customers Support </h3>
						</div>
						<div class="em-service-text">
							<p> Customer support falls under the customer service umbrella yet prioritizes customers who have trouble or require assistance with a product or service they purchased. Customer support teams provide technical assistance. </p>
						</div>
						<div class="service-bar"></div>
					</div>
					<div class="dreamit-service-back">
						<div class="serivce-back-title">
							<h3> Support </h3>
							<h2> Customer support </h2>
						</div>
						<div class="serivce-back-icon">
							<Link to="#">+</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

{/* <div class="demo my-4" bis_skin_checked="1">
    <div class="container-fluid  p-md-5" bis_skin_checked="1">
        <div class="row" bis_skin_checked="1">
            <div class="col-md-6 col-sm-12 col-lg-4" bis_skin_checked="1">
                <div class="pricingTable blue" bis_skin_checked="1">
                    <div class="pricingTable-header" bis_skin_checked="1">
                        <h3 class="title">Basic</h3>
                        <div class="price-value" bis_skin_checked="1">
                            <span class="currency">$</span>
                            <span class="amount my-3">399.99/</span>
                            <span class="duration">per month</span>
                        </div>
                    </div>
                    <ul class="pricing-content">
                        <li>Web Development</li>
                        <li>Application Development</li>
                        <li>Product Hunting</li>
                        <li>Product Launching</li>
                        <li>CRM</li>
                    </ul>
                    <div class="pricingTable-signup" bis_skin_checked="1">
                        <Link href="#">Contact Us</Link>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-4" bis_skin_checked="1">
                <div class="pricingTable blue" bis_skin_checked="1">
                    <div class="pricingTable-header" bis_skin_checked="1">
                        <h3 class="title">Standard</h3>
                        <div class="price-value" bis_skin_checked="1">
                            <span class="currency">$</span>
                            <span class="amount">599.99/</span>
                            <span class="duration">per month</span>
                        </div>
                    </div>
                    <ul class="pricing-content">
                        <li>Web Development</li>
                        <li>Application Development</li>
                        <li>Product Hunting</li>
                        <li>Product Launching</li>
                        <li>CRM</li>
						<li>Customer Service (First Year Free)</li>
                        <li>Social Media Handling</li>

                    </ul>
                    <div class="pricingTable-signup" bis_skin_checked="1">
                        <Link href="#">Contact Us</Link>
                    </div>
                </div>
            </div>
			<div class="col-md-6 col-sm-12 col-lg-4" bis_skin_checked="1">
                <div class="pricingTable blue" bis_skin_checked="1">
                    <div class="pricingTable-header" bis_skin_checked="1">
                        <h3 class="title">Premium</h3>
                        <div class="price-value" bis_skin_checked="1">
                            <span class="currency">$</span>
                            <span class="amount">999.99 /</span>
                            <span class="duration">per month</span>
                        </div>
                    </div>
                    <ul class="pricing-content">
                        <li>Web Development</li>
                        <li>Application Development</li>
                        <li>Product Hunting</li>
                        <li>Product Launching</li>
                        <li>CRM</li>
						<li>Customer Service (First Year Free)</li>
                        <li>Social Media Handling</li>
                        <li>Book Keeping</li>
						<li>NFT Creating</li>
                        <li>Web designing (First Year Free)</li>
                        <li>Creating Token If Business Required.</li>
                    </ul>
                    <div class="pricingTable-signup" bis_skin_checked="1">
                        <Link href="#">Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}

<div class="brand-section upper">
		<div class="container">
			<div class="brand-list row">
				<div class="col-lg-3 col-6">
					<div class="brand-single-box">
						<div class="brand-thumb">
							<img src={Img11} alt="" width={200}/>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="brand-single-box">
						<div class="brand-thumb">
							<img src={Img12} alt="" width={200}/>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="brand-single-box">
						<div class="brand-thumb">
							<img src={Img13} alt="" width={200}/>
						</div>
					</div>
				</div>
				<div class="col-lg-3 col-6">
					<div class="brand-single-box">
						<div class="brand-thumb">
							<img src={Img14} alt="" width={200}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	
</div>

</div>
  )
}

export default Service